import * as Sentry from '@sentry/vue';

export function useUtils() {
    const snackbar = useSnackbarStore();
    const authStore = useAuthStore();
    const comm = useCommStore()
    const router = useRouter();
    const globalLoading = useLoadingStore();

    const download = async (url: string, fileName: string = '') => {
        try {
            globalLoading.startLoading('waiting...');
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText} (${response.status})`);
            }

            const totalSize = Number(response.headers.get('content-length'));
            let loaded = 0;

            const reader = response.body?.getReader();
            if (!reader) throw new Error('ReadableStream is not supported');

            const stream = new ReadableStream({
                async start(controller) {
                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) break;

                        loaded += value.length;
                        // 将获取到的流数据发送给前端
                        controller.enqueue(value);

                        // 计算并显示下载进度
                        const percent = Math.round((loaded / totalSize) * 100);
                        globalLoading.startLoading(`${percent}%`);
                    }

                    controller.close();
                    reader.releaseLock();
                },
            });

            const blob = await new Response(stream).blob();

            const link = document.createElement('a');
            const objectURL = window.URL.createObjectURL(blob);
            link.href = objectURL;

            // 使用 URL 对象解析文件名
            const urlObj = new URL(url);
            const defaultFileName = urlObj.pathname.split('/').pop() || 'download';

            link.download = fileName || defaultFileName;
            document.body.appendChild(link);
            link.click();

            // Clean up after a slight delay to ensure the download has started
            setTimeout(() => {
                window.URL.revokeObjectURL(objectURL);
                document.body.removeChild(link);
            }, 100); // 100ms 延迟确保下载已开始
        } catch (error) {
            snackbar.showError(`Failed to download file: ${error.message}`);

            // 上报错误到 Sentry，增加用户信息和标签
            Sentry.captureException(error, {
                tags: {
                    component: 'download',
                    severity: 'error',
                },
                extra: {
                    url,
                    fileName,
                    status: error.message,
                    userAgent: navigator.userAgent,
                },
                user: {
                    uid: comm?.userInfo.uid,
                    username: comm?.userInfo.nickname
                },
            });

            console.error('Download error:', error);
        } finally {
            globalLoading.stopLoading();
        }
    };

    const loadScript = (src: string): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const script = document.createElement('script');
            script.src = src;
            script.defer = true;
            script.onload = () => resolve();
            script.onerror = () => reject(new Error(`Failed to load script ${src}`));
            document.head.appendChild(script);
        });
    };

    function loginByToken(token: string, redirectTo?: string) {
        if (!token) return;
        authStore.login(token);
        const route = useRoute();
        console.log('fullPath', route.fullPath);

        if (redirectTo) {
            router.push(redirectTo);
        } else if (route.fullPath === '/login/google') {
            router.push('/my/account');
        }
    }

    return {
        download,
        loadScript,
        loginByToken,
    };
}